@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-size: 16px;
}

#hello-bar a {
    @apply text-blue-700 underline;
}

#Home {
    background: #3591fc;
    background: linear-gradient(to top, #006ce8, #57a5ff);
}

#Packages {
    .mathContain {
        p {
            @apply mt-2;
        }
        h1 {
            @apply my-5 text-4xl font-extrabold;
        }
    }

    .cryptoAnswerCol {
        h1 {
            @apply mb-2;
        }
        .cryptoSideContain {
            @apply pt-2;
        }
    }

    h2 {
        @apply text-2xl font-extrabold;
    }

    h3 {
        @apply text-lg font-extrabold;
    }

    .linkItem {
        @apply inline-flex items-center;
    }
}

.svgIcon {
    .st0,
    svg {
        fill: currentColor;
    }
}

.arrow-left:before {
    content: "";
    height: 0;
    top: 50%;
    transform: translate(-100%, -50%);
    position: absolute;
    width: 0;
    left: 0;
    z-index: 0;
    border: 5px solid transparent;
    border-right-color: inherit;
}
