.container {
    padding: 0 6px 10px;
    font-size: 14px;
    font-family: helvetica neue, sans-serif;
}

.headline {
    color: #1a0dab;
    font-size: 19px;
    font-weight: 400;
}

.link {
    color: #008000;
    text-decoration: none;
    word-break: break-all;
}

.flag {
    margin-left: 0.25rem;
    color: #008000;
    text-decoration: none;
}

.flag svg {
    height: 0.75em;
    width: 0.75em;
    display: none;
}

.flag:not(:hover) svg:last-child {
    display: inline-block;
}

.flag:hover svg:first-child {
    display: inline-block;
}

.ad {
    font-size: 12px;
    line-height: 12px;
    display: inline-block;
    border-radius: 3px;
    margin-right: 7px;
    vertical-align: baseline;
    padding: 0 3px 0px 2px;
    border: 1px solid #008000;
}

.description {
    color: #4e616c;
}

.arrow {
    height: 14px;
    width: 14px;
    padding-right: 3px;
}

.visit {
    display: flex;
    justify-content: flex-start;
}

.visitButton {
    max-width: 200px;
    height: 28px;
    cursor: pointer;
    margin: 4px 0 7px;
    padding: 0 5px;
    border-radius: 2px;
    text-decoration: none;
    font-size: 14px;
    line-height: 1.5em;
    color: #1a0dab;
    font-weight: normal;
    vertical-align: bottom;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.08s linear, min-width 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    margin-left: 1px;
}

.visitButton:hover {
    text-decoration: underline;
}
